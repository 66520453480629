<template>
  <div class="d-inline-block">
    <t-button :variant="btnStyle" size="sm" @click="view()" :class="{'fixed-button-width': fixedWidth}" class="text-right">
      {{btnText}} <fluency-icon :class="['ml-2', {rotating: generating}]" :type="!generating ? 'document' : 'loop'"/>
    </t-button>
    <t-modal v-if="showModal" v-model="showModal" ok-title="Download Report" size="mostly-full" title="Configuration" @ok="downloadReport()">
      <t-row>
        <t-col cols="4">
          <template v-if="viewType === 'prospect'">
            <prospect-configuration v-model="configuration"
                                    :loading-pdf="loadingPDF"
                                    @update:loading-pdf="loadingPDF = $event"
                                    @update="holdChanges($event, true)"/>
            <div class="text-right">
              <t-button :variant="(changes.evt)?'warning':'success'" @click="viewProspect(changes.evt, changes.customize)">Update PDF</t-button>
            </div>
          </template>
          <proposal-configuration v-else v-model="configuration" @update="view()"/>
        </t-col>
        <t-col cols="8">
          <template v-if="configuration.pathToProposal">
            <embed :src="embedSrc" style="width:100%;height:calc(100vh - 100px);" type="application/pdf"/>
          </template>
        </t-col>
      </t-row>
    </t-modal>
  </div>
</template>

<script>
import ProposalConfiguration from './proposalConfiguration'
import ProspectConfiguration from '@/components/pages/prospecting/prospectConfiguration'

export default {
  name: 'viewProposal',
  components: {
    ProspectConfiguration,
    ProposalConfiguration
  },
  props: ['accountPlanId', 'buttonText', 'buttonStyle', 'accountName', 'viewType', 'initialConfiguration', 'fixedWidth'],
  data () {
    return {
      generating: false,
      showModal: false,
      configuration: {},
      updateIframeCount: 0,
      loadingPDF: false,
      configurationResponse: {},
      changes: {}
    }
  },
  watch: {
    initialConfiguration: {
      handler (newValue, oldValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          this.$setCompat(this, 'configuration', newValue)
        }
      },
      immediate: true
    }
  },
  computed: {
    fluencyUser () {
      return this.$store.getters.isFluencyUser
    },
    btnText () {
      return this.buttonText || 'View Proposal'
    },
    btnStyle () {
      return this.buttonStyle || 'secondary'
    },
    embedSrc () {
      if (this.configuration.pathToProposal === 'blank') {
        return 'about:blank'
      } else {
        return `https://fluent-asset.s3.us-east-2.amazonaws.com/${this.configuration.pathToProposal}?${this.updateIframeCount}`
      }
    },
    downloadSrc () {
      if (this.configuration.pathToProposal === 'blank') {
        return 'about:blank'
      } else {
        return `https://fluent-asset.s3.us-east-2.amazonaws.com/${this.configuration.pathToProposal || this.configuration.prospectingProposalPdfLink}`
      }
    }
  },
  methods: {
    view (evt, customize) {
      if (this.viewType === 'prospect') {
        this.viewProspect(evt, customize)
      } else {
        this.viewProposal(evt, customize)
      }
    },
    holdChanges (evt, customize) {
      this.changes = {
        evt: evt,
        customize: customize
      }
    },
    async viewProposal () {
      this.$setCompat(this, 'showModal', true)
      const response = await this.$res.fetch.proposalLink(this.accountPlanId, this.configuration)
      if (response) {
        this.$setCompat(this, 'configuration', response)
      }
      this.$setCompat(this, 'updateIframeCount', ++this.updateIframeCount)
    },
    async viewProspect (evt, customize) {
      if (!customize) {
        if (!this.validateProspect()) {
          return false
        }
      }
      this.$setCompat(this, 'generating', true)
      const customer = await this.$res.customer.get()
      this.$setCompat(this, 'configuration', {
        ...this.configuration,
        customerId: customer.customerId
      })

      const response = await this.$res.fetch.prospectLink(this.configuration, customize || this.configurationResponse.customerName === this.configuration.customerName)
      if (response) {
        this.$setCompat(this, 'configuration', response)
        this.configuration.pathToProposal = response.prospectingProposalPdfLink
        this.configurationResponse = this.configuration

        this.$setCompat(this, 'showModal', true)

        return true
      }

      this.$setCompat(this, 'generating', false)
      this.changes = {}

      if (!response) {
        this.$alert({
          title: 'Uh oh...',
          text: 'We were unable to generate a prospect proposal with this data.'
        })
        return false
      }
    },
    validateProspect () {
      if (this.configuration.requestedQuery || (this.configuration.preparer.length > 0 && this.configuration.customerName.length > 0 && this.configuration.address.length > 0 && this.configuration.approximateRadiusAroundAddress)) {
        return true
      } else {
        this.$alert('We need Customer Name, Prospect Name, Address, and Radius entered in order to generate a prospect.')
      }
      return false
    },
    downloadReport () {
      this.downloadResource(this.downloadSrc)
    },
    forceDownload (blob, filename) {
      const a = document.createElement('a')
      a.download = filename
      a.href = blob
      // For Firefox https://stackoverflow.com/a/32226068
      document.body.appendChild(a)
      a.click()
      a.remove()
    },
    async downloadResource (url, filename) {
      let fileNameText = filename
      if (!fileNameText) {
        let accountName = this.accountName || this.configuration.requestedQuery?.customerName || 'UNKNOWN'
        accountName = accountName.replace(/[<>:"/\\|?*]+/g, '')
        fileNameText = 'Proposal for ' + accountName + '.pdf'
      }

      await this.$res.fetch.pdf(url, fileNameText)
      /*
      fetch(url, {
        headers: new Headers({
          'Origin': location.origin
        }),
        mode: 'cors'
      }).then(response => response.blob()).then(blob => {
        let blobUrl = window.URL.createObjectURL(blob)
        this.forceDownload(blobUrl, filename)
      }).catch(e => console.error(e))
       */
    }
  }
}
</script>

<style scoped>
.fixed-button-width {
  width:73px;
}
</style>
