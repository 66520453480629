<!-- this file allows for the very basic prospect data required to initially seed a prospect PDF -->
<template>
  <div>
    <b-form-group description="The email address that you want to see on the rendered PDF for contact information." label="Email Address">
      <b-input v-model="config.preparer"/>
    </b-form-group>
    <b-form-group description="Name of the potential customer that you want to reach out to." label="Prospect Name">
      <b-input v-model="config.customerName"/>
    </b-form-group>
    <div class="row">
      <b-form-group class="col-9" label="Address">
        <b-input v-model="config.address" placeholder="15 Any street, City, ST 00000"/>
      </b-form-group>
      <b-form-group class="col">
        <template #label>
          Radius
        </template>
        <b-input v-model="config.approximateRadiusAroundAddress"/>
      </b-form-group>
    </div>
    <div class="row">
      <div class="col-9">
        <prospect-markup v-model="config"/>
      </div>
      <!-- <div v-if="showButton" class="col-4">
        <t-button style="margin-top:2em;" @click="$emit('click')">Generate Prospects</t-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import ProspectMarkup from './prospectMarkup'
import _ from 'lodash'
export default {
  name: 'prospectConfigurationBasic',
  components: { ProspectMarkup },
  props: ['value', 'showButton'],
  data () {
    return {
      config: {
        preparer: '',
        customerName: '',
        address: '',
        approximateRadiusAroundAddress: null,
        markupFlat: 0,
        markupPercentage: 0
      }
    }
  },
  watch: {
    value: {
      handler (newVal) {
        if (typeof newVal === 'object') {
          this.$setCompat(this, 'config', newVal)
        }
      },
      immediate: true
    },
    config: {
      handler: _.debounce(function (newVal) {
        this.$emit('input', newVal)
      }, 1000),
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
